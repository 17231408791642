import React from 'react';
import { Location } from '@reach/router';
import queryString from 'query-string';

function WithLocation(ComponentToWrap) {
  return function WrappedWithLocation(props) {
    return (
      <Location>
        {({ location, navigate }) => (
          <ComponentToWrap
            {...props}
            location={location}
            navigate={navigate}
            search={location.search ? queryString.parse(location.search) : {}}
          />
        )}
      </Location>
    );
  };
}
//WrappedWithLocation.displayName = 'WrappedWithLocation';

export default WithLocation;
