import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line
import chartStyles from './chart.css';

class MonthLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      months: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
    };
  }

  render() {
    var rendered = [];
    rendered.push(<td key="-1"></td>); //day column

    var labelMonth = this.state.months[0];
    var columnColspan = 0;
    for (var index = 0; index < this.props.listSundayDays.length; index++) {
      var month = new Date(this.props.listSundayDays[index].date).getMonth();

      if (!isNaN(month)) {
        if (labelMonth != this.state.months[month]) {
          rendered.push(
            <td key={index} colSpan={columnColspan}>
              {labelMonth}
            </td>
          );
          labelMonth = this.state.months[month];
          columnColspan = 1;
        } else {
          columnColspan += 1;
        }
      } else {
        rendered.push(<td key={index}></td>);
      }
    }
    rendered.push(
      <td key={index} colSpan={columnColspan}>
        {labelMonth}
      </td>
    );

    return <tr className="rowInfo">{rendered}</tr>;
  }
}

MonthLine.propTypes = {
  listSundayDays: PropTypes.array.isRequired,
};

class DayLine extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <tr>
        <td className="rowInfo">
          {this.props.day === 1 ? 'Mon' : ''}
          {this.props.day === 3 ? 'Wed' : ''}
          {this.props.day === 5 ? 'Fri' : ''}
        </td>
        {this.props.data.map((elt, i) => (
          <td key={i} className={'intensity intensity' + elt.intensity}></td>
        ))}
      </tr>
    );
  }
}

DayLine.propTypes = {
  day: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
};

class TitleChart extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { data, year, username } = this.props;
    var nbContribution = 0;
    if (data.contributions) {
      for (var index = 0; index < data.contributions.length; index++) {
        nbContribution +=
          data.contributions[index].year == year
            ? data.contributions[index].count
            : 0;
      }
    }
    return (
      <div className="titleChart">
        <span>
          {nbContribution} contributions in {year} by {username}
        </span>
      </div>
    );
  }
}

TitleChart.propTypes = {
  data: PropTypes.object.isRequired,
  year: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
};

class Chart extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var contributions = this.props.data.contributions;
    var dayOfWeek;
    var listDays = [[], [], [], [], [], [], []];

    var self = this;

    if (contributions) {
      contributions.map(function(elt) {
        if (elt.year == self.props.year) {
          if (!dayOfWeek) {
            dayOfWeek = new Date(elt.date).getDay();
            for (var d = 0; d < dayOfWeek; d++) {
              listDays[d].push({ intensity: 99 });
            }
          }
          listDays[dayOfWeek].push(elt);
          dayOfWeek = (dayOfWeek + 1) % 7;
        }
      });
    }

    var maxColumn = Math.max.apply(
      Math,
      listDays.map(function(el) {
        return el.length;
      })
    );
    const thead = [];
    for (var index = 0; index < maxColumn; index++) {
      thead.push(<td key={index}></td>);
    }

    return (
      <div className="printChart">
        <TitleChart
          data={this.props.data}
          year={this.props.year}
          username={this.props.username}
        />
        <div className="contentChart">
          <table className="chart">
            <thead>
              <tr>{thead}</tr>
            </thead>
            <tbody>
              <MonthLine listSundayDays={listDays[0]} />
              {listDays.map((elt, i) => (
                <DayLine key={i} day={i} data={elt} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

Chart.propTypes = {
  data: PropTypes.object.isRequired,
  year: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
};

export default Chart;
