import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import WithLocation from '../containers/withLocation';
import Chart from 'components/chart/chart';
import Head from 'components/head';
import GlobalStyle from 'global.css.js';

class Contributions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datacontribution: {},
      username: '',
      year: new Date().getFullYear(),
    };
  }

  componentDidMount() {
    const { username, year } = this.props.search;
    this.fetchDataContributions(username, year);
  }

  fetchDataContributions(username, year) {
    fetch(`${process.env.GATSBY_API_URL}` + username, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        this.setState({
          datacontribution: data,
          username: username,
          year: parseInt(year),
        });
      })
      .catch();
  }

  render() {
    const styleZoom = { zoom: '3' };
    return (
      <div style={styleZoom}>
        <GlobalStyle />
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <Head />
        {this.state.datacontribution ? (
          <Chart
            data={this.state.datacontribution}
            year={this.state.year}
            username={this.state.username}
          />
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

Contributions.propTypes = {
  search: PropTypes.object,
};

export default WithLocation(Contributions);
